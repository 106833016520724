.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.condition-value {
  color: #d2e8ef;
}

.negative-value {
  color: #7d94f0 !important;
}

::-webkit-scrollbar {
  width: 8px; 
}

::-webkit-scrollbar-thumb {
  background-color: #555; 
  border-radius: 10px; 
}

::-webkit-scrollbar-thumb:hover {
  background-color: #888; 
}

::-webkit-scrollbar-track {
  background-color: #333; 
}

.table-container{
  margin-bottom: 20px;
}

.sticky-header {
  position: fixed;
  top: 0;
  background-color: #121212;
  z-index: 1;
  display: none;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 50px;;
  margin-left: 24px;
}
.sticky-header th {
  border-top: 1px solid #555;
  border-bottom: 1px solid #555;
}
.sticky-header th, td {
  border: 1px solid #555;
  text-align: center;
  font-weight: 700;
}